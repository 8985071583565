/*
	doesn't necessary filter them
	unless ownerID or dayOfWeek are
	specified

	always parses
*/

/*
	import { parseReservations } from "@/utilities/amenities/parseReservations"

	const { reservations } = filterReservations ({
		amenityReservations: [
			// { startDate, endDate, reservationOwnerID },
			// ...
			// { startDate, endDate, reservationOwnerID },
		],

		// optional
		ownerID,

		// optional
		dayOfWeek: "Thursday"
	});
*/

import { timeIsInvalid } from '@/utilities/time/isInvalid'
import { wkDaysNums } from '@/utilities/Date/constants'
//
import _get from 'lodash/get'
import moment from 'moment'
//
//
export function parseReservations({ amenityReservations, ownerID = null, dayOfWeek = null }) {
  const dates = {
    /* 2021-11-04 */
  }

  for (let a = 0; a < amenityReservations.length; a++) {
    const reservation = amenityReservations[a]

    const sDate = _get(reservation, 'startDate', '').split('T')
    const eDate = _get(reservation, 'endDate', '').split('T')

    if (timeIsInvalid(sDate[1])) {
      console.error('invalid date', { sDate })
      continue
    }
    if (timeIsInvalid(eDate[1])) {
      console.error('invalid date', { eDate })
      continue
    }

    const reservationOwnerID = _get(reservation, 'reservationOwnerID', '')
    if (typeof ownerID === 'number') {
      if (reservationOwnerID !== ownerID) {
        continue
      }
    }

    if (typeof dayOfWeek === 'string') {
      try {
        const rDayOfWeek = wkDaysNums[moment(_get(reservation, 'startDate', '')).day() + 1]
        if (rDayOfWeek.toLowerCase() !== dayOfWeek.toLowerCase()) {
          continue
        }
      } catch (x) {
        console.error(x)
      }
    }

    if (!Array.isArray(dates[sDate[0]])) {
      dates[sDate[0]] = []
    }
    dates[sDate[0]].push([sDate[1], eDate[1], reservation])
  }

  return {
    reservations: dates
  }
}
