/*
	import { approveAmenityReservation } from '@/services/Amenities/AmenityReservation/ApproveReservation';

  const { successful, message } = await approveAmenityReservation ({
    amenityReservationID
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function approveAmenityReservation({ amenityReservationID }) {
  let message = 'The amenity reservation request could not be approved.'

  try {
    await kms.post(
      `/Amenities/AmenityReservation/ApproveReservation?amenityReservationID=${amenityReservationID}`
    )

    return {
      successful: true,
      message
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message
  }
}
