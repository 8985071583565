/*
	import { addAmenity } from '@/services/Amenities/Amenity/Post';

  const { successful, message } = await addAmenity ({
    json: {
			hoaID
    }
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function addAmenity({ json }) {
  let message = 'The amenity could not be added.'

  try {
    const result = await kms.post(`/Amenities/Amenity`, json)

    return {
      successful: true,
      message,
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: null
  }
}
