/*
	import { deleteAmenityReservation } from '@/services/Amenities/AmenityReservation/Delete';

  const { successful, message } = await deleteAmenityReservation ({
    amenityReservationID,
    params: {
      isBeingRejectedByManager: true
    }
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import _get from 'lodash/get'
import kms from '@/services/kms'

export async function deleteAmenityReservation({ amenityReservationID, params: _params }) {
  let message = 'The amenity reservation could not be deleted.'

  const isBeingRejectedByManager = _get(_params, 'isBeingRejectedByManager', null)
  let params = `forceDelete=false`
  if (isBeingRejectedByManager === true) {
    params += `&isBeingRejectedByManager=true`
  }

  const path = `/Amenities/AmenityReservation/${amenityReservationID}?${params}`

  try {
    await kms.delete(path, {
      params: _params
    })

    return {
      successful: true,
      message
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message
  }
}
