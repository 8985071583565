/*
	import { addReservableTime } from '@/services/Amenities/AmenityReservableTime/Post';

  const { successful, message } = await addReservableTime ({
    json: {
      "amenityID": 14,
      "dayOfWeek": "Wednesday",
      "startTime": "2023-01-11T01:08:16.817Z",
      "endTime": "2023-01-11T01:11:16.817Z",
      "cleanupDurationInMinutes": 18,
    }
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function addReservableTime({ json }) {
  let message = 'The amenity could not be added.'

  console.log({ json })

  try {
    await kms.post(`/Amenities/AmenityReservableTime`, json)

    return {
      successful: true,
      message
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message
  }
}
