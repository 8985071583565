/*
	import { calcOwnerEventTotals } from '@/utilities/amenities/calculate/ownerEventTotals'

	const { total } = calcOwnerEventTotals ({
		amenityData,
		ownerID
	})
*/

import _get from 'lodash/get'
import moment from 'moment'
import { parseReservations } from '@/utilities/amenities/parseReservations'
import { reservationIsApproved } from '@/utilities/amenities/reservationIsApproved'

export function calcOwnerEventTotals({ amenityData, ownerID }) {
  const amenityReservations = _get(amenityData, ['amenityReservations'], [])
  const requiresApproval = _get(amenityData, ['requiresApproval'], null)

  const now = moment()

  // filters by ownerID
  const { reservations: dates } = parseReservations({
    amenityReservations,
    ownerID
  })

  const your = {
    future: {
      reservations: {
        count: 0,
        list: []
      },
      requests: {
        count: 0,
        list: []
      }
    },
    past: {
      reservations: {
        count: 0,
        list: []
      },
      requests: {
        count: 0,
        list: []
      }
    }
  }

  /*
		calculate 
	*/
  for (let key in dates) {
    const thisDate = moment(key)
    const isPast = thisDate.isBefore(now)
    const reservations = dates[key]

    for (let a = 0; a < reservations.length; a++) {
      const r = _get(reservations, [a, 2], null)

      const reservation = _get(reservations, [a, 2], null)
      const isApproved = reservationIsApproved({
        reservation
      })

      if (isPast) {
        if (requiresApproval === true) {
          if (isApproved) {
            your.past.reservations.count += 1
            your.past.reservations.list.push(r)
          } else {
            your.past.requests.count += 1
            your.past.requests.list.push(r)
          }
        } else {
          your.past.reservations.count += 1
          your.past.reservations.list.push(r)
        }
      } else {
        if (requiresApproval === true) {
          if (isApproved) {
            your.future.reservations.count += 1
            your.future.reservations.list.push(r)
          } else {
            your.future.requests.count += 1
            your.future.requests.list.push(r)
          }
        } else {
          your.future.reservations.count += 1
          your.future.reservations.list.push(r)
        }
      }
    }
  }

  return {
    totals: your
  }
}
