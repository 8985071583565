// /*
//   import { amenityReservationStore } from '@/services/Amenities/AmenityReservation/store'
//})
// */

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError } from '@/services/notify'
import kms from '@/services/kms'

/* eslint-disable no-empty-pattern */
const actions = {
  async getAmenityReservationById({}, { amenityReservationID }) {
    try {
      console.debug('in getAmenityReservationById...')
      const result = await kms.get(`/Amenities/AmenityReservation/${amenityReservationID}`)

      console.debug('getAmenityReservationById=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving this amenity reservation.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async cancelWithRefund({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.post(
        `/Amenities/AmenityReservation/CancelReservation?amenityReservationID=${payload.amenityReservationID}`
      )

      console.debug('cancelWithRefund=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.amenityReservationID > 0) {
        console.debug(`Successfully refunded the cancellation of this amenity reservation.`)
        done({ detail: results })
      } else {
        notifyError('There was a problem refunding the cancellation of this amenity reservation.')
      }
    } catch (exception) {
      if (exception.message.indexOf('did not pass validation') > -1) {
        notifyProblem(exception.message)
      } else {
        notifyProblem(
          `There was a problem refunding the cancellation for this amenity reservation.`
        )
      }

      console.error(exception)
    }
  },

  async updateAmenityReservationForPaymentOnApproval({ dispatch }, { params = {}, done }) {
    try {
      //retrieve by id
      var existingAmenityReservation = await kms.get(
        `/Amenities/AmenityReservation/${params.amenityReservationID}`
      )

      if (existingAmenityReservation !== undefined && existingAmenityReservation) {
        console.debug('params for update=' + JSON.stringify(params))

        if (params.tokenNumber !== undefined && params.tokenNumber && params.tokenNumber !== '') {
          existingAmenityReservation.tokenNumber =
            params.tokenNumber !== undefined && params.tokenNumber ? params.tokenNumber : null
        } else {
          existingAmenityReservation.tokenNumber = null
        }

        if (params.isApprovalPending !== undefined && params.isApprovalPending === true) {
          existingAmenityReservation.isApprovalPending = true
        } else {
          existingAmenityReservation.isApprovalPending = null
        }

        if (
          params.ownerPaymentMethodID !== undefined &&
          params.ownerPaymentMethodID &&
          params.ownerPaymentMethodID > 0
        ) {
          existingAmenityReservation.ownerPaymentMethodID =
            params.ownerPaymentMethodID !== undefined && params.ownerPaymentMethodID
              ? params.ownerPaymentMethodID
              : null
        } else {
          existingAmenityReservation.ownerPaymentMethodID = null
        }

        const payload = existingAmenityReservation

        const results = await kms.put(`/Amenities/AmenityReservation`, payload)

        if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

        if (dispatch && dispatch != undefined) console.debug(dispatch)

        if (results.amenityReservationID > 0) {
          console.debug(`Successfully updated this amenity reservation for payment on approval.`)
          done({ detail: results })
        } else {
          notifyError(
            'There was a problem updating this amenity reservation for payment on approval.'
          )
        }
      }
    } catch (exception) {
      if (exception.message.indexOf('did not pass validation') > -1) {
        notifyProblem(exception.message)
      }
      notifyProblem(
        `There was a problem updating this amenity reservation for payment on approval.`
      )
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const amenityReservationStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
