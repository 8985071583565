/*
	import { reservationIsApproved } from '@/utilities/amenities/reservationIsApproved'

	const isApproved = reservationIsApproved ({ reservation });
*/

import _get from 'lodash/get'

export function reservationIsApproved({ reservation }) {
  const approvedByAspNetUserID = _get(reservation, 'approvedByAspNetUserID', null)
  if (typeof approvedByAspNetUserID === 'string' && approvedByAspNetUserID.length >= 2) {
    return true
  }

  return false
}
