/*
  import { amenityStore } from '@/services/Amenities/store'

  this.unsubAmenityStore = amenityStore.subscribe((mutation, state) => {
    const mType = mutation.type;


  })

  this.unsubAmenityStore();
*/

import { addAmenity } from '@/services/Amenities/Amenity/Post'
import { addReservableTime } from '@/services/Amenities/AmenityReservableTime/Post'
import { updateAmenity } from '@/services/Amenities/Amenity/Put'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export const amenityStore = new Vuex.Store({
  state: {},
  mutations: {
    async amenityAdded() {},
    async amenityReservableTimeAdded() {},
    async amenityReservableTimeRemoved() {},
    async amenityReservationAdded() {},
    async amenityReservationCancelled() {},
    async amenityUpdated() {},

    /*
			await amenityStore.commit ('updateMain');
    */
    async updateMain() {}
  },
  /* eslint-disable no-empty-pattern */
  actions: {
    /*
	    const { successful, message } = await amenityStore.dispatch ('addAmenity', {
	      json: {
					
	      }
	    });
	  */
    async addAmenity({ commit }, { json }) {
      const { successful, message } = await addAmenity({
        json
      })
      if (successful) {
        commit('amenityAdded')
      }

      return {
        successful,
        message
      }
    },

    async updateAmenity({ commit }, { json }) {
      const { successful, message } = await updateAmenity({
        json
      })
      if (successful) {
        commit('amenityUpdated')
      }

      return {
        successful,
        message
      }
    },
    /*
	    const { successful, message } = await amenityStore.dispatch ('addReservableTime', {
	      json: {
					
	      }
	    });
	  */
    async addReservableTime({ commit }, { json }) {
      const { successful, message } = await addReservableTime({
        json
      })
      if (successful) {
        commit('amenityReservableTimeAdded')
      }

      return {
        successful,
        message
      }
    }
  },
  /* eslint-enable no-empty-pattern */
  getters: {}
})
