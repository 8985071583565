/*
	import { updateAmenity } from '@/services/Amenities/Amenity/Put';

  const { successful, message } = await updateAmenity ({
    json: {
			hoaID
      ...
    }
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function updateAmenity({ json }) {
  let message = 'The amenity could not be updated.'

  try {
    const result = await kms.put(`/Amenities/Amenity/UpdateMinimal`, json)

    return {
      successful: true,
      message,
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: null
  }
}
